import React, {useCallback } from 'react';
import {Route} from "react-router-dom";

import { useKeycloak } from '@react-keycloak/web'

function PrivateRoute({ component: Component, authenticated, ...rest }) {

  const { keycloak } = useKeycloak()
  const login = useCallback(() => {
    keycloak?.login()
  }, [keycloak])

  return (<Route
    {...rest}
    render={props => keycloak?.authenticated ?<Component authenticated={authenticated} {...rest} {...props} />: login()} />);

}

export default PrivateRoute