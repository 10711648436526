import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web'

import keycloak from './keycloak'
import { LOCAL_STORAGE_KEYS } from './Constantes';
const eventLogger = (event, error) => {
  // console.log('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens) => {
  // console.log('onKeycloakTokens', tokens)
   localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, tokens.token);
 }

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    onEvent={eventLogger}
    onTokens={tokenLogger}>
    <Router>
        <App />
    </Router>
  </ReactKeycloakProvider>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
