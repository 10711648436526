import React from 'react';
import { Tooltip } from 'antd';
import { LIST_APPS } from '../util/Helpers';

const Home = () => {
    return (
        <div id='homePage' className='mt-4'>
            <div className="row">
                {LIST_APPS?.filter(el => el.visible).map((app, i) => (
                    <div key={app.name + "-" + i} className="col-12 col-md-4 mb-3">
                        <Tooltip title={app.description} placement='bottom' >
                            <a href={app.link}>
                                <div className="card p-2 text-white" style={{ height: '200px', backgroundColor: app.color }}>
                                    <div className="row">
                                        <div className="m-0 col-3">
                                            <span style={{ fontSize: 50, position: "", top: "" }} >
                                                <i className={app.primeicon} style={{ fontSize: 50 }}></i>
                                            </span>
                                        </div>
                                        <div className="col-9 ">
                                            <h2 className="mt-3"><b className="text-white">{app.name}</b></h2>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-12 text-center ">
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </Tooltip>

                    </div>
                ))}
                {/* 
                <div className="col-12 col-md-3 mb-3">
                    <div className="card p-2 text-white" style={{ height: '200px', backgroundColor: '#fccb00' }}>
                        <div className="row">
                            <div className="m-0 col-3">
                                <span style={{ fontSize: 50, position: "", top: "" }} >
                                    <i className="pi pi-shopping-cart" style={{ fontSize: 50 }}></i>
                                </span>
                                <span style={{ fontSize: 30, position: "", top: "" }} >
                                    <i className="pi pi-home" style={{ fontSize: 20 }}></i>
                                </span>
                            </div>
                            <div className="col-9 ">
                                <h2 className="mt-3"><b className="text-white">Commande Cash Port</b></h2>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 text-center ">
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-3 mb-3">
                    <div className="card p-2 text-white" style={{ width: '', height: '200px', backgroundColor: '#004dcf' }}>
                        <div className="row">
                            <div className="m-0 col-3">
                                <span style={{ fontSize: 50, position: "", top: "" }} >
                                    <i className="pi pi-chart-line" style={{ fontSize: 50 }}></i>
                                </span>
                                <span style={{ fontSize: 30, position: "", top: "" }} >
                                    <i className="pi pi-money-bill" style={{ fontSize: 20 }}></i>
                                </span>
                            </div>
                            <div className="col-9">
                                <h2 className="mt-3"><b className="text-white">Rapport de solde</b></h2>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 text-center ">
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-3 mb-3">
                    <div className="card p-2 " style={{ width: '', height: '200px', backgroundColor: 'white', borderColor: 'danger', border: 'solid 2px' }}>
                        <div className="row">
                            <div className="m-0 col-3">
                                <span style={{ fontSize: 50, position: "", top: "" }} >
                                    <i className="pi  pi-book" style={{ fontSize: 50 }}></i>
                                </span>
                                <span style={{ fontSize: 30, position: "", top: "" }} >
                                    <i className="pi pi-money-bill" style={{ fontSize: 20 }}></i>
                                </span>
                            </div>
                            <div className="col-9">
                                <h2 className="mt-3"><b className="text-black">Catalogue</b></h2>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 text-center ">
                            </div>
                        </div>
                    </div>
                </div> 

                <div className="col-12 col-md-3 mb-3">
                    <div className="card p-2 text-white" style={{ width: '', height: '200px', backgroundColor: '#bccbcb' }}>
                        <div className="row">
                            <div className="m-0 col-3">
                                <span style={{ fontSize: 50, position: "", top: "" }} >
                                    <i className="pi pi-folder-open" style={{ fontSize: 50 }}></i>
                                </span>
                                <span style={{ fontSize: 30, position: "", top: "" }} >
                                    <i className="pi pi-money-bill" style={{ fontSize: 20 }}></i>
                                </span>
                            </div>
                            <div className="col-9">
                                <h2 className="mt-3"><b className="text-white">Suivi des factures</b></h2>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 text-center ">
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-3 mb-3">
                    <div className="card p-2 text-white" style={{ width: '', height: '200px', backgroundColor: '#365276' }}>
                        <div className="row">
                            <div className="m-0 col-3">
                                <span style={{ fontSize: 50, position: "", top: "" }} >
                                    <i className="pi pi-shopping-cart" style={{ fontSize: 50 }}></i>
                                </span>
                                <span style={{ fontSize: 30, position: "", top: "" }} >
                                    <i className="pi pi-user" style={{ fontSize: 20 }}></i>
                                </span>

                            </div>
                            <div className="col-9">
                                <h2 className="mt-3"><b className="text-white">Livraison direct</b></h2>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-12 text-center ">
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>

        </div>
    );
};




export default Home;