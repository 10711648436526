import React, { useEffect } from "react";
import { Switch, withRouter } from "react-router-dom";

import "./App.css";
import Home from "./view/Home";
import PrivateRoute from './app_common/PrivateRoute';
import AntLayout from './view/ant/AntLayout';
import NotFound from './app_common/NotFound';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import 'antd/dist/antd.css';
import { useKeycloak } from '@react-keycloak/web'
import { APP_URL, LOCAL_STORAGE_KEYS } from './Constantes';


function App(props) {

  const { initialized, keycloak } = useKeycloak()
  const currentUser = {
    firstName: null,
    lastName: null,
    fullName: null,
    email: null,
    username: null,
    roles: []
  };

  
  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(840);
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);
  
  if (!initialized) {
    // console.log("keycloak not init ===>", keycloak)
    return <div>Chargement...</div>
  } else {
    // console.log("keycloak init ===>", keycloak)
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, keycloak.token);
    if (keycloak.idToken) {
      currentUser.firstName = keycloak?.idTokenParsed?.given_name
      currentUser.lastName = keycloak?.idTokenParsed?.family_name
      currentUser.fullName = keycloak?.idTokenParsed?.name
      currentUser.email = keycloak?.idTokenParsed?.email
      currentUser.username = keycloak?.idTokenParsed?.preferred_username
      currentUser.roles = keycloak?.realmAccess?.roles
      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_USER, JSON.stringify(currentUser));
    }
  }

  const logOut = () => {
    // console.log("log out")
    keycloak.logout()
  }
 



  return (
    <div className="App">
      <div style={{ minHeight: "100%", height: "100%" }} >
        <LayoutChooser isAuthenticated={true} handleLogout={logOut} currentUser={currentUser} {...props}>
          <Switch>
            <PrivateRoute currentUser={currentUser} exact path={APP_URL.HOME.value} component={Home} />
            <PrivateRoute currentUser={currentUser} component={NotFound} />
          </Switch>
        </LayoutChooser>
      </div>
    </div>
  );
}

function LayoutChooser(props) {
  if (props.isAuthenticated) {
    return (<AntLayout {...props} >{props.children}</AntLayout>)
  } else {
    return (<div className='container-fluid' >{props.children}</div>)
  }
}
export default withRouter(App);
