import { KEYCLOAK_URL } from './Constantes';
import Keycloak from 'keycloak-js'

const keycloakConfig = { 
   url: KEYCLOAK_URL, 
   realm: 'CASHPORT', 
   clientId: 'store-manager',
   onLoad:'login-required'
}
const keycloak = new Keycloak(keycloakConfig);
export default keycloak