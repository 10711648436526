import React, { Component } from 'react';
import { Dropdown, Layout, Menu } from 'antd';
import { PoweroffOutlined, UserOutlined, DownOutlined } from '@ant-design/icons';
import { APP_URL } from '../../Constantes';

const { Header } = Layout;
class AppHeader extends Component {
    render() {
        const menu = (
            <Menu>
                {/* <Menu.Item key="1" onClick={() => this.props.handleLogout()}>
                    <UserOutlined /> &nbsp; Mon compte
                </Menu.Item> */}
                <Menu.Item key="2" onClick={() => this.props.handleLogout()}>
                    <PoweroffOutlined /> &nbsp; Se déconnecter
                </Menu.Item>
            </Menu>
        );
        return (
            <div id="AppHeader"  >
                {/* <Header style={{ backgroundColor: "rgba(45,80,145,1)", padding: 0 }} > */}
                <Header className='psm_bg_dark p-0 m-0'>
                    <div className="container-fluid"> 
                    <div className="row">
                            <div className="col-12 col-md-4 text-left">
                                <a href={APP_URL.HOME.value}>
                                    <b className="text-light text-1">APP MANAGER</b>
                                </a>
                            </div>
                            <div className="col-12 col-md-4 text-center">
                                {/* <b className="text-light ">BONPRIX TEST KEYCLOAK CLASSIQUE</b> */}
                            </div>
                            <div className="col-12 col-md-4 text-end">
                                <UserOutlined style={{ color: '#fff' }} /> &nbsp;
                                <Dropdown overlay={menu}>
                                    <a href="#/" className="ant-dropdown-link text-light" onClick={e => e.preventDefault()}>
                                        {this.props.currentUser && this.props.currentUser.fullName} &nbsp;
                                        <DownOutlined />
                                    </a>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </Header>
            </div>
        )
    }
}
export default AppHeader;
