import React from 'react';
import { Layout } from 'antd';
import MyHeader from "./AppHeader";
import 'antd/dist/antd.css';
import './AntLayout.css'; 

const { Content, Footer } = Layout;

function AntLayout(props) {
 

    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <MyHeader {...props} />
                <Layout>
                    <Content style={{ margin: '0 10px' }}>                        
                        <section style={{ padding: 24, minHeight: 360 }}>{props.children}</section>
                    </Content>
                </Layout>
                <Footer style={{ textAlign: 'center' }}>App Manager © {new Date().getFullYear()} Design by PROSUMA</Footer>
            </Layout>
        </div>
    );
}

export default AntLayout;